<template>
  <div>
    <b-form @submit.prevent="submitCreate" enctype="multipart/form-data">
      <b-card no-body class="mb-1">
        <b-card-header>
          <div>
            <b-card-title class="mb-1"> Dados fiscais </b-card-title>
            <b-card-sub-title>
              Os dados inseridos abaixo serão utilizados na emissão de nota
              fiscal.
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body class="pl-2 pr-2">
          <div class="form-row">
            <div class="col-md-3">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Nome Fantasia
                  <i
                    class="bi bi-question-circle"
                    v-b-tooltip.hover.top="'Nome de fantasia ou apelido'"
                  ></i>
                </label>
                <input
                  type="text"
                  :class="{ 'is-invalid': $v.item.company_name.$error }"
                  v-model="item.company_name"
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Nome
                  <i
                    class="bi bi-question-circle"
                    v-b-tooltip.hover.top="
                      'Nome registrado na junta comercial ou nome completo do contato.'
                    "
                  ></i>
                </label>
                <input
                  type="text"
                  :class="{ 'is-invalid': $v.item.trading_name.$error }"
                  v-model="item.trading_name"
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label for="">
                  CNPJ/CPF
                </label>
                <b-input-group>
                  <input
                    class="form-control"
                    type="text"
                    v-model="item.document"
                    v-mask="['###.###.###-##', '##.###.###/####-##']"
                  />
                </b-input-group>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label for=""> Categoria </label>
                <v-select
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.category"
                  placeholder="Digite o nome"
                  :options="optionsCategories"
                  :searchable="false"
                >
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Histórico PC
                </label>
                <v-select
                  :searchable="true"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.plan"
                  :options="optionsPlans"
                >
                  <span slot="no-options">
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label for="">
                  Inscrição Estadual
                  <i
                    class="bi bi-question-circle"
                    v-b-tooltip.hover.top="
                      'A inscrição estadual é o registro das empresas perante a Receita Estadual.'
                    "
                  ></i>
                </label>
                <input
                  type="text"
                  v-model="item.state_license"
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label for=""> Inscrição Municipal </label>
                <input
                  type="text"
                  v-model="item.city_license"
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-md-2">
              <div class="form-group">
                <label for=""> Abertura </label>
                <input
                  type="text"
                  placeholder="dd/mm/aaaa"
                  v-model="item.date"
                  v-mask="['##/##/####']"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group mb-md-0">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Situação
                </label>
                <v-select
                  label="title"
                  item-text="title"
                  item-value="code"
                  :searchable="false"
                  v-model="item.active"
                  :options="optionsStatus"
                  :class="{ 'is-invalid': $v.item.active.$error }"
                >
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
      <b-tabs pills class="mb-2">
        <b-tab active>
          <template #title>
            <span class="d-none d-sm-inline">Contatos</span>
          </template>
          <b-card no-body class="mb-1">
            <b-card-header>
              <div>
                <b-card-title class="mb-1"> Contatos </b-card-title>
                <b-card-sub-title>
                  Cadastre os meios de contato do fornecedor
                </b-card-sub-title>
              </div>
            </b-card-header>
            <b-card-body class="pl-2 pr-2">
              <div class="form-row">
                <div class="col-md-6">
                  <div class="form-group mb-0 mb-m-1">
                    <label for=""> E-mail </label>
                    <input type="email" v-model="item.email" class="form-control" />
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group mb-0 mb-m-1">
                    <label for=""> Telefone </label>
                    <input
                      type="tel"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                      v-model="item.phone"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group mb-0 mb-m-1">
                    <label for=""> Celular </label>
                    <input
                      type="tel"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                      v-model="item.cellphone"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group mb-0 mb-m-1">
                    <label for=""> WhatsApp </label>
                    <input
                      type="tel"
                      v-model="item.whatsapp"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-tab>

        <b-tab>
          <template #title>
            <span class="d-none d-sm-inline">Endereço</span>
          </template>
          <b-card no-body class="mb-1">
            <b-card-header>
              <div>
                <b-card-title class="mb-1"> Endereço </b-card-title>
                <b-card-sub-title>
                  Digite o endereço onde a empresa está localizada
                </b-card-sub-title>
              </div>
            </b-card-header>
            <b-card-body class="pl-2 pr-2">
              <div class="form-row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for=""> CEP </label>
                    <input
                      type="text"
                      class="form-control"
                      @keyup="searchCep()"
                      v-mask="['#####-###']"
                      v-model="item.zipcode"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Número</label>
                    <input type="text" v-model="item.number" class="form-control" />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Complemento</label>
                    <input
                      type="text"
                      v-model="item.complement"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Endereço</label>
                    <input
                      type="text"
                      v-model="item.address"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group mb-0 mb-m-1">
                    <label for="">Bairro</label>
                    <input
                      type="text"
                      v-model="item.neighborhood"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group mb-0 mb-m-1">
                    <label for="">Cidade</label>
                    <input type="text" v-model="item.city" class="form-control" />
                  </div>
                </div>
                <div class="col-md-1">
                  <div class="form-group mb-0 mb-m-1">
                    <label for="">Estado</label>
                    <input type="text" v-model="item.state" class="form-control" />
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-tab>

        <b-tab>
          <template #title>
            <span class="d-none d-sm-inline">Documentos</span>
          </template>
          <b-card no-body class="mb-1">
            <b-card-header class="mb-0">
              <div>
                <div class="form-row">
                  <div class="col-md-11">
                    <b-card-title class="mb-1"> Documentos </b-card-title>
                    <b-card-sub-title>
                      Adicione documentos do fornecedor caso precise utilizar
                      futuramente
                    </b-card-sub-title>
                  </div>
                  <div class="col-md-1">
                    <button
                      @click="selectFiles"
                      type="button"
                      class="btn btn-block btn-primary"
                    >
                      <i class="bi bi-plus-circle"></i>
                    </button>
                  </div>
                </div>
              </div>
            </b-card-header>
            <b-card-body class="p-2">
              <div class="form-row">
                <div class="col-md-12">
                  <div class="form-group mb-0">
                    <input
                      id="files"
                      type="file"
                      multiple
                      class="form-control-file hidden"
                      @change="previewDocs"
                      accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                    />

                    <p
                      v-if="!item.docs.length"
                      class="pt-5 py-4 text-center mb-0"
                      style="
                        border: 1px solid rgb(222 222 222);
                        border-radius: 4px;
                        border-style: dashed;
                        font-size: 1.1rem;
                      "
                    >
                      <i class="bi bi-file-earmark-text"></i>
                      Selecionar documentos
                      <small class="text-center mt-1 d-block">
                        Selcione apenas arquivos nos formatos: xlsx, xls, imagem,
                        doc, docx, ppt, pptx, txt e pdf
                      </small>
                    </p>

                    <label class="d-block text-center mb-0">
                      <ul
                        class="list-group list-group-light"
                        id="array-files"
                        v-if="item.docs.length"
                      >
                        <li
                          class="list-group-item border-0 bg-light rounded-5 mb-5-px text-left"
                          v-for="(doc, index) in item.docs"
                          :key="index"
                        >
                          <div class="form-row">
                            <div class="col-md-1 text-center">
                              <i
                                :class="`bi bi-filetype-${doc.name
                                  .split('.')
                                  .pop()}`"
                              ></i>
                            </div>
                            <div class="col-md-9 text-left">
                              {{ doc.name }}
                            </div>
                            <div class="col-md-2 text-center">
                              {{ doc.size | bytesToMB(2, "MB") }}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </label>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-tab>

        <b-tab>
          <template #title>
            <span class="d-none d-sm-inline">Observações</span>
          </template>
          <b-card no-body class="mb-1">
            <b-card-header>
              <div>
                <b-card-title class="mb-1"> Observações gerais </b-card-title>
                <b-card-sub-title>
                  Caso tenha mais alguma informação ou observação sobre a empresa
                </b-card-sub-title>
              </div>
            </b-card-header>
            <b-card-body class="pl-2 pr-2">
              <textarea
                v-model="item.note"
                class="d-block form-control"
                rows="4"
              ></textarea>
            </b-card-body>
          </b-card>
        </b-tab>

        <b-tab>
          <template #title>
            <span class="d-none d-sm-inline">Conta bancária</span>
          </template>
          <b-card no-body class="mb-1">
            <b-card-header>
              <div>
                <b-card-title class="mb-1">Conta bancária</b-card-title>
                <b-card-sub-title>
                  Cadastre a conta bancária que o fornecedor receberá o pagamento.
                </b-card-sub-title>
              </div>
            </b-card-header>
            <b-card-body class="pl-2 pr-2">
              <div class="form-row align-items-center">
                <div class="col-md-4">
                  <div class="form-group mb-md-0">
                    <label for="">
                      Banco
                    </label>
                    <v-select label="title" required :searchable="true" v-model="item.bank_account.bank" :options="optionsBanks"
                      :clearable="true" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group mb-md-0">
                    <label for="">
                      Chave PIX
                    </label>
                    <input type="text" v-model="item.bank_account.pix_key" class="form-control">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group mb-md-0">
                    <label for="">
                      Agência
                    </label>
                    <input type="text" v-model="item.bank_account.agency" class="form-control">
                  </div>
                </div>               
                <div class="col-md-2">
                  <div class="form-group mb-md-0">
                    <label for="">
                      Nº da conta
                    </label>
                    <input type="text" v-model="item.bank_account.account_number" class="form-control">
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-tab>
      </b-tabs>

      <div class="form-row justify-content-end">
        <div class="col-md-2">
          <button type="submit" class="btn btn-block btn-success">
            <div v-if="submited">
              <b-spinner small variant="light" /> Verificando...
            </div>
            <div v-else>Salvar</div>
          </button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import {
  BCard,
  BSpinner,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BCardBody,
  BInputGroup,
  BFormInput,
  VBTooltip,
  BTabs,
  BTab,
  BInputGroupAppend,
  BForm,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

import BtnSaveAndBack from "@/components/button/SaveAndBack";

import { required } from "vuelidate/lib/validators";

import axios from "axios";

import vSelect from "vue-select";
import "swiper/css/swiper.css";

const checkRequired = (value) => {
  console.log(value)
}
export default {
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  components: {
    BCard,
    BSpinner,
    BForm,
    BtnSaveAndBack,
    BCardHeader,
    BCardBody,
    BCardTitle,
    VBTooltip,
    BTabs,
    BTab,
    BCardSubTitle,
    BFormCheckbox,
    vSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  data() {
    return {
      docs: [],
      submited: false,
      uuid: '',
      item: {
        company_name: "",
        trading_name: "",
        category: "",
        plan: "",
        docs: [],
        business: true,
        date: "",
        email: "",
        state_license: "",
        city_license: "",
        phone: "",
        cellphone: "",
        whatsapp: "",
        address: "",
        complement: "",
        city: "",
        state: "",
        number: "",
        zipcode: "",
        neighborhood: "",
        active: "",
        note: "",
        bank_account: {
          agency: "",
          account_number: "",
          bank: "",
          pix_key: "",
        }
      },
      optionsStatus: [
        {
          title: "Ativo",
          code: 1,
        },
        {
          title: "Inativo",
          code: 0,
        },
      ],
      optionsCategories: [],
      optionsTypeCompanies: [],
      optionsBankAccounts: [],
      optionsBanks: [],
      optionsPlans: [],
    };
  },
  methods: {
    async getData() {
      this.$store.dispatch("Supplier/show", this.uuid).then((res) => {
        this.item = res;
        this.docs = res.docs;
        this.item.docs = [];
      });
    },
    deleteMedia(mediaUuid) {
      this.$swal({
        title: "Quer excluir?",
        text: "Esta ação será irreversível!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(`Supplier/deleteMedia`, {
              uuid: this.uuid,
              media_uuid: mediaUuid,
            })
            .then(() => {
              this.notifyDefault("success");
              this.getData();
            });
        }
      });
    },
    async fetchBankAccount(term) {
      this.optionsBankAccounts = []
      if (term.length > 2) {
        this.optionsBankAccounts = await this.$store.dispatch("BankAccount/forSelect", {
          term: term
        })
      }
    },
    selectFiles() {
      this.$el.querySelector("#files").click();
    },
    deleteFile(event) {
      this.item.docs.splice(this.item.docs.indexOf(event), 1);
    },
    previewDocs(event) {
      const input = event.target;
      if (input.files) {
        const files = input.files;

        this.item.docs = files;
      }
    },
    async getCategories() {
      this.$store.dispatch("Supplier/forSelectCategories").then((res) => {
        this.optionsCategories = res;
      });
    },
    async getTypeCompanies() {
      this.$store.dispatch("Config/forSelectTypeCompanies").then((res) => {
        this.optionsTypeCompanies = res;
      });
    },
    searchCNPJ() {
      axios
        .get(
          `https://receitaws.com.br/v1/cnpj/${this.item.document.replace(
            /\D/g,
            ""
          )}`
        )
        .then((res) => {
          console.log(res);
        });
    },
    searchCep() {
      if (this.item.zipcode.length == 9) {
        axios
          .get(`https://viacep.com.br/ws/${this.item.zipcode}/json/`)
          .then((res) => {
            const data = res.data;

            this.item.address = data.logradouro;
            this.item.neighborhood = data.bairro;
            this.item.city = data.localidade;
            this.item.state = data.uf;
          })
          .catch((error) => console.log(error));
      }
    },
    submitCreate() {
      this.$v.$touch();
      this.submited = true;

      if (!this.$v.$error) {
        const formData = new FormData();

        formData.append("document", this.item.document ?? "");
        formData.append("company_name", this.item.company_name);
        formData.append("trading_name", this.item.trading_name);

        formData.append("email", this.item.email);
        formData.append("cellphone", this.item.cellphone);
        formData.append("phone", this.item.phone);
        formData.append("whatsapp", this.item.whatsapp);

        formData.append(
          "category",
          this.item.category ? this.item.category.code : ""
        );

        formData.append("state_license", this.item.state_license);
        formData.append("city_license", this.item.city_license);
        formData.append("date", this.item.date);
        formData.append(
          "active",
          this.item.active ? this.item.active.code : ""
        );

        formData.append("zipcode", this.item.zipcode);
        formData.append("plan", this.item.plan ? this.item.plan.code : '');
        formData.append("number", this.item.number);
        formData.append("complement", this.item.complement);
        formData.append("address", this.item.address);
        formData.append("neighborhood", this.item.neighborhood);
        formData.append("city", this.item.city);
        formData.append("state", this.item.state);

        formData.append("note", this.item.note);

        formData.append("bank_account", JSON.stringify(this.item.bank_account));

        var files = this.item.docs;

        for (var index = 0; index < files.length; index++) {
          formData.append("docs[]", files[index]);
        }

        this.$store
          .dispatch("Supplier/update", { uuid: this.uuid, data: formData })
          .then(() => {
            this.notifyDefault("success");
            this.$router.push({ name: "suppliers-list" });
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
  },
  validations: {
    item: {
      company_name: {
        required,
      },
      trading_name: {
        required,
      },
      active: {
        required,
      },
    },
  },
  mounted() {
    this.getCategories();
    this.getTypeCompanies();

    this.uuid = this.$route.params.uuid;

    this.getData();
    
    this.$store.dispatch("Bank/forSelect").then((res) => {
      this.optionsBanks = res
    })

    this.$store.dispatch("OrderPurchase/forSelectPlans").then((res) => {
      this.optionsPlans = res;
    });
  },
};
</script>
